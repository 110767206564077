import React from "react";
import { HeaderStyled } from "./style";

function Header() {
  return (
    <HeaderStyled>
      <a href="https://www.searchagora.com/">
        <img src="/logo.svg" alt="" />
      </a>
    </HeaderStyled>
  );
}

export default Header;
