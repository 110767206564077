import styled from "styled-components";

export const StyledFooterContainer = styled.div`
  text-align: center;
  h4 {
    color: #212529;
    margin: 1em 0;
    a {
      text-decoration: none;
      color: var(--orange);
    }
  }
`;
