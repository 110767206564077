import axios from "axios";

export const checkWebsiteStatus = async (url) => {
  try {
    const config = {
      method: "get",
      url: url,
      // withCredentials: false,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    const response = await axios(config);
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.error("Error checking website status:", error);
    return false;
  }
};
