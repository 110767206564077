import styled from "styled-components";

export const StyledCardContainer = styled.div`
  padding: 1.5em;
  background-color: var(--white);
  border-radius: 10px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.6);
  @media (max-width: 768px) {
    width: unset;
  }
`;
