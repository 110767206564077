import styled from "styled-components";

export const HeaderStyled = styled.div`
  //   padding: 0.5em 2em;
  // background-color: var(--white);
  //   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  a {
    position: absolute;
    left: 3em;
    top: 3em;
    img {
      height: 45px;
    }
    @media (max-width: 768px) {
      position: static;
      // left: 50%;
      // transform: translate(-50%);
    }
  }
`;
