import React from "react";
import { StyledFooterContainer } from "./style";

const Footer = () => {
  return (
    <StyledFooterContainer>
      <h4>
        Having trouble? Contact{" "}
        <a href="mailto:support@searchagora.com">support.</a>
      </h4>
    </StyledFooterContainer>
  );
};

export default Footer;
